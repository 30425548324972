@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    label {
        @apply h-6 relative inline-block;
    }

    button {
        @apply text-sm leading-normal;
        @apply px-5 py-2 rounded-xl shadow-inner
    }

    button.primary {
        @apply bg-indigo-500 text-white font-semibold text-sm leading-normal;
        @apply px-5 py-2 rounded-xl shadow-inner
    }

    [type="checkbox"] {
        @apply w-11 h-0 cursor-pointer inline-block;
        @apply focus:outline-0 dark:focus:outline-0;
        @apply border-0 dark:border-0;
        @apply focus:ring-offset-transparent dark:focus:ring-offset-transparent;
        @apply focus:ring-transparent dark:focus:ring-transparent;
        @apply focus-within:ring-0 dark:focus-within:ring-0;
        @apply focus:shadow-none dark:focus:shadow-none;
        
        @apply after:absolute before:absolute;
        @apply after:top-0 before:top-0;
        @apply after:block before:inline-block;
        @apply before:rounded-full after:rounded-full;

        @apply after:content-[''] after:w-5 after:h-5 after:mt-0.5 after:ml-0.5;
        @apply after:shadow-md after:duration-100;

        @apply before:content-[''] before:w-10 before:h-full;
        @apply before:shadow-[inset_0_0_#000];

        @apply after:bg-white dark:after:bg-gray-50;
        @apply before:bg-gray-300 dark:before:bg-gray-600;
        @apply before:checked:bg-indigo-500 dark:before:checked:bg-indigo-500;
        @apply checked:after:duration-300 checked:after:translate-x-4;

        @apply disabled:after:bg-opacity-75 disabled:cursor-not-allowed;
        @apply disabled:checked:before:bg-opacity-40;
    }

    [type="password"] {
        @apply text-zinc-500 text-sm leading-snug;
        @apply bg-white rounded-lg border border-gray-200;

        @apply disabled:bg-gray-200
    }

    [type="email"] {
        @apply text-zinc-500 text-sm leading-snug;
        @apply bg-white rounded-lg border border-gray-200;

        @apply disabled:bg-gray-200
    }

    [type="text"] {
        @apply text-zinc-500 text-sm leading-snug;
        @apply bg-white rounded-lg border border-gray-200;

        @apply disabled:bg-gray-200
    }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Amplify UI */
#root {
  /*text-align: center;*/
  margin: 1.2rem;
}

nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

[data-amplify-authenticator] {
    --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
    --amplify-components-authenticator-router-border-width: 0;
    --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
    --amplify-components-button-primary-background-color: rgb(99,102,241);
    /* --amplify-components-button-primary-background-color: var(--amplify-colors-blue-80); */
    --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--amplify-colors-purple-60);
    --amplify-components-tabs-item-active-border-color: var(--amplify-colors-neutral-100);
    --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
    --amplify-components-tabs-item-active-color: var(--amplify-colors-purple-100);
    --amplify-components-button-link-color: var(--amplify-colors-purple-80);
}
