.tooltip {
    /* @apply invisible absolute z-50 w-auto bg-black rounded p-1; */
    bottom: 130%;
    left: 50%;
    margin-left: -30px;
}

.has-tooltip .tooltip::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.has-tooltip {
    @apply relative;
}

.has-tooltip:hover .tooltip {
    @apply visible;
}
